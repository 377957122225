.tiptap {
  h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.73px;
  }
  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
  }
  h3 {
    margin: 0;
    font-size: 19px;
    font-weight: 600;
    line-height: 22.99px;
  }
  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
  }
  h5 {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
  }
  h6 {
    margin: 0;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.31px;
  }
  p {
    margin: 0;
  }
  ul {
    padding: 0px 12px 0px 12px;
    color: #111c36;

    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
    }
  }
}

.editorContentWrapper {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: #111c36;
  }
}
